import {staticCDNProvider} from '@shelf/client-helpers';
import {styled} from 'styled-components';

const Oops = styled.div`
  background: url('${`${staticCDNProvider()}/images/404/oopps.png`}') no-repeat 0 0;
  height: 128px;
  background-size: contain;
  margin: 1vh 11vw 3vh 0;

  @media only screen and (width <= 700px) {
    background-position: center;
    margin: 0;
    order: 2;
    background-size: 300px;
    height: 110px;
  }
`;
const WomanWithGlassesDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  min-width: 350px;

  @media only screen and (width <= 700px) {
    height: auto;
  }
`;
const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  min-width: 320px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media only screen and (width <= 700px) {
    flex-direction: column;
  }
`;

const Girl = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 260px;
  text-align: right;
  height: 100%;

  @media only screen and (width <= 700px) {
    order: 2;
    text-align: center;
  }
`;

const Caption = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 66%;
  align-self: flex-start;

  @media only screen and (width <= 700px) {
    order: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const Paragraph = styled.div`
  text-align: center;

  @media only screen and (width <= 700px) {
    order: 1;
    padding: 15px 15px 0;
  }
`;

const Description = styled.div`
  color: #807c7c;
  font-size: 20px;
  font-weight: 600;
`;

const Img = styled.img`
  height: 80vh;
`;

const Attention = styled.i`
  color: #f00;
`;

const WomanWithGlasses = (): JSX.Element => {
  return (
    <>
      <WomanWithGlassesDiv>
        <Wrapper>
          <Girl>
            <Img src={`${staticCDNProvider()}/images/404/404.svg`} alt="not found" />
          </Girl>
          <Caption>
            <Oops />
            <Paragraph>
              <Description>
                {`We can't seem to find what you are looking for.`}
                {process.env.ENVIRONMENT === 'dev' ? (
                  <>
                    <br />
                    <Attention>!!! Please inspect the terminal for any errors</Attention>
                  </>
                ) : null}
              </Description>
            </Paragraph>
          </Caption>
        </Wrapper>
      </WomanWithGlassesDiv>
    </>
  );
};
export default WomanWithGlasses;
